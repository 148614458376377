<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text {{ page.title }} - Buy Amazon Inc.
        .container.text-right.gap-bottom
            .content.mt-5(v-html="page.content")
            //.row.mt-5
                .col-sm-12
                    h3 לורם איפסום
                    p לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח. עמחליף הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.

                    |הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.

                    |לפרומי בלוף קינץ תתיח לרעח. לת צשחמי צש בליא, מנסוטו צמלח לביקו ננבי, צמוקו בלוקריה שיצמה ברורק. לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.|

                    |סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת. הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.
                    h3 דולור
                    p קונדימנטום קורוס בליקרה, נונסטי קלובר בריקנה סטום, לפריקך תצטריק לרטי.

                    |	להאמית קרהשק סכעיט דז מא, מנכם למטכין נשואי מנורךגולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ, ושבעגט. נולום ארווס סאפיאן - פוסיליס קוויס, אקווזמן קוואזי במר מודוף. אודיפו בלאסטיק מונופץ קליר, בנפת נפקט למסון בלרק - וענוף לפרומי בלוף קינץ תתיח לרעח. לת צשחמי לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית. סת אלמנקום ניסי נון ניבאה. דס איאקוליס וולופטה דיאם. וסטיבולום אט דולור, קראס אגת לקטוס וואל אאוגו וסטיבולום סוליסי טידום בעליק. קולהע צופעט למרקוח איבן איף, ברומץ כלרשט מיחוצים. קלאצי גולר מונפרר סוברט לורם שבצק יהול, לכנוץ בעריר גק ליץ,|
                    h3 סיט אמאט
                    p Buy Amazon הינו שירות הלוגיסטיקה והשילוח ליבוא אישי או מסחרי מארה"ב המשתלם ביותר בישראל. השירות מציע פתרונות ליבוא אישי או מסחרי, קניות בכל אתר אמריקאי, משלוחים מאתרים מובילים כגון: amazon, Ebay, GAP. בנוסף צוות BuyAmazon פועל להשיג דילים משתלמים על מוצרים מארה"ב בתחומי – אלקטרוניקה ומחשבים, משפחה וילדים, טיפוח ובריאות, ספורט ופנאי ועוד. תוכלו לקבל מאיתנו מידע וטיפים הכרחיים שיעזרו לכם לחסוך המון בפייסבוק ובמייל. הירשמו עכשיו!

</template>


<style lang="scss">
    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }
        }


        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }
    }

    .container {
        @media only screen and (max-width: 767px) {
            .gap-bottom {
                padding-bottom: 6rem;
                width: 100% !important;
            }
        }
    }
</style>


<script lang="js">
    export default {
        computed: {
            page() {
                return this.$store.state.DISCLAMER_PAGE
            }
        }
    }
</script>
